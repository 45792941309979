<template>
    <div id="contentcontainter" class="standard-mode">
        <v-dialog id="endoftest-dialog" v-model="dialog" max-width="800px" persistent>
            <div class="maincard">
                <div class="vh">
                    <div>
                        <div class="wrap">
                            <img src="../assets/maintenance.svg" width="200px" class="illustration" alt="" />
                            <h1>Maintenance mode</h1>
                            <h2>
                                <p>Sorry for the inconvenience.<br />Quaro is currently undergoing scheduled maintenance.<br /><br /></p>
                            </h2>
                            <div class="msgbox">
                                <span class="text">Thank you for your understanding.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import auth from "../components/helper/auth.js"
    export default {
        name: "Maintenance",
        data() {
            return { dialog: true }
        },
        computed: {},
        methods: {},
        mounted() {
            let interval = setInterval(() => {
                auth.apiOnline().then((response) => {
                    console.log(response)
                    if (response || response == undefined) {
                        clearInterval(interval)
                        console.log("api online again, redirecting...")
                        this.$router.push({ path: "/" })
                    }
                })
            }, 10000)
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        width: 100%;
        height: 100%;
    }
    .maincard {
        text-align: center;
        margin: 0px;
        padding: 0px;
        height: 100%;
        color: #fff;
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        -webkit-animation: Gradient 15s ease infinite;
        -moz-animation: Gradient 15s ease infinite;
        animation: Gradient 15s ease infinite;
    }
    .vh {
        height: 100%;
        align-items: center;
        display: flex;
    }
    .vh > div {
        width: 100%;
        text-align: center;
        vertical-align: middle;
    }
    img {
        max-width: 100%;
    }
    .wrap {
        margin-top: 50px;
        text-align: center;
    }
    .wrap h1 {
        font-size: 30px;
        font-weight: 700;
        margin: 0 0 90px;
    }
    .wrap h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.6;
        margin: 0 0 80px;
    }
    .msgbox {
        display: flex;
        margin-bottom: 50px;
        .text {
            border: 1px dashed rgb(253, 253, 253);
            border-radius: 5px;
            padding: 2px 7px 2px 7px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @-webkit-keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes Gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
</style>
